@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

body {
    background-size: 100% 100%;
    font-family: 'Special Elite', cursive;
    margin: 0;
}

html {
    background-color: black;
    background-image: url(images/background.png);
    background-repeat:initial;
    height: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
}