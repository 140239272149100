.project-main-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
}

.project-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    margin-bottom: 400px;
}

.project-content-container {
    align-items: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    text-align: center;

    border-radius: 80px;
    border-width: 40px;
    left: 30%;
    height: 400px;
    padding: 20px;
    margin-top: -320px;
    margin-right: 44%;
    overflow: hidden;
    top: 30%;
    width: 65%;
    
    background: rgba(0, 0, 0, 0.5);
    text-shadow: 0 0 0.125em rgba(254, 254, 254);

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -moz-box-shadow: 0px 0px 44px 1px rgba(18, 74, 167, 1), 0px 0px 20px 1px rgba(18, 74, 167, 1);
    -webkit-box-shadow: 0px 0px 44px 1px rgba(18, 74, 167, 1), 0px 0px 20px 1px rgba(18, 74, 167, 1);
}

.project-card {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;

    border: 5px solid #242329;
    border-radius: 80px;
    height: 390px;
    margin-top: -5px;
    overflow: hidden;
    width: 100%;

    background-image: linear-gradient(90deg, rgba(95,51,82,1) 21%, rgba(68,56,116,1) 100%);
    color: linear-gradient(90deg, rgba(193,160,224,1) 21%, rgba(157,59,173,1) 100%);
}


.project-image-container {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 100%;
    top: -100px;
    width: 100%;
}

.project-image {
    height: 100%;
    width: 100%;
    top: -200px;

    transition: transform .35s .35s; 
}

#project-unwind-image {
    padding-top: 2.5px;
}

.project-data {
    position: absolute;

    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    
    transform: translateY(-310px);
    transition: transform .5s;
}

.project-title {
    font-size: 55px;
    margin-bottom: -28px;

    color: #fff;
    text-transform: uppercase;
}

.project-description {
    line-height: 25px;
    margin-bottom: 10px;
    padding: 20px;
    
    color: rgba(254, 254, 254, 0.718)
}

.project-technology {
    font-size: 30px;

    top: 5px;
    /* top: -20px; */
    margin-bottom: -38px;
    margin-top: 10px;
}

.project-technologies {
    line-height: 22px;
    
    margin-bottom: 10px;
    margin-top: 55px;
    top: 5px;
}

.project-links {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;

    height: 100%;
    left: 70%;
    overflow-x: hidden;
    padding: 5px;
    padding-top: 15px;
    top: -140px;
    width: 15%;
    z-index: 1;

    transition: 0.5s ease;
}

.external-link {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    background-color: #846bff;
    border-radius: 50%;
    padding: 12px;
    padding-left: 5px;
    padding-right: 5px;
    
    text-decoration: none;
    transition: background-color .25s;
}

.external-link:hover {
    border-radius: 50%;

    background-color: #242329;
    color: #fff;
}

.project-card:hover .project-data:hover {
    transform: translateY(0px);
}

.project-card:hover .project-image {
    transform: translateY(700px);
    transition-delay: -25s;
}
