.about-main-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    height: 100%;
    width: 100%;
}

.about-content-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
    text-align: center;

    border-radius: 150px;
    border-width: 40px;

    top: 30%;
    left: 30%;
    width: 55%;
    height: 350px;
    padding: 20px;
    margin-top: -300px;
    margin-right: 44%;
    
    background: rgba(0, 0, 0, 0.5);
   
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -moz-box-shadow: 0px 0px 44px 1px rgba(18, 74, 167, 1), 0px 0px 20px 1px rgba(18, 74, 167, 1);
    -webkit-box-shadow: 0px 0px 44px 1px rgba(18, 74, 167, 1), 0px 0px 20px 1px rgba(18, 74, 167, 1);
   
    text-shadow: 0 0 0.125em rgba(254, 254, 254);
}

/* ---------------------- TYPEWRITER ----------------- */
.about-typewriter {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    
    position: inherit;
    text-align: center;
    bottom: 25%;

   
}

/* --------------------- BUTTON ----------------------- */

.about-button-container {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;

   top: 75%;
}

.about-button {
    align-items: center;
    appearance: none;
    background-color: initial;
    background-image: none;
    border-style: none;
    border-radius: 80px;
    bottom: 35px;
    left: 17px;
    
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    font-family: monospace;
    font-size: 18px;
    font-weight: 550;
    justify-content: center;
    line-height: 24px;
    min-height: 24px;
    
    
    padding: 33px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;

    box-shadow: inset 0px 0px 20px 1px rgba(248, 74, 167, 1), 0px 0px 20px 1px rgba(248, 74, 167, 1);
    -webkit-box-shadow: 0px 0px 44px 1px rgba(248, 74, 167, 1), 0px 0px 20px 1px rgba(248, 74, 167, 1);
    -moz-box-shadow: 0px 0px 44px 1px rgba(248, 74, 167, 1), 0px 0px 20px 1px rgba(248, 74, 167, 1);
}
  
  
.about-button:before,
.about-button:after {
    border-radius: 80px;
}
  
.about-button:before {
    background-image: linear-gradient(90deg, rgba(193,160,224,1) 21%, rgba(157,59,173,1) 100%);
    color: white;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
}

.about-button:after {
    background-color: initial;
    background-image: linear-gradient(90deg, rgba(95,51,82,1) 21%, rgba(68,56,116,1) 100%);;
    bottom: 4px;
    color: black;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
}

.about-button:hover:not(:disabled):before {
    background: linear-gradient(90deg, rgba(193,160,224,1) 21%, rgba(157,59,173,1) 100%);
}

.about-button:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
}

.about-button:active:not(:disabled) {
    color: #ccc;
}

.about-button:active:not(:disabled):before {
    background-image: linear-gradient(90deg, rgba(193,160,224,1) 21%, rgba(157,59,173,1) 100%);
}

.about-button:active:not(:disabled):after {
    background-image: linear-gradient(90deg, rgba(95,51,82,1) 21%, rgba(68,56,116,1) 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
}

.about-button:disabled {
    cursor: default;
    opacity: .24;
}